import React, { memo } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

import { Navbar, Footer, SEO } from "../../components";

import { Masonry } from "../../components/ui";

const Ujszulott = () => {
  const images = useStaticQuery(graphql`
    query ujszulottPortfolioQuery {
      allFile(
        filter: {
          sourceInstanceName: { eq: "gallery" }
          relativeDirectory: { eq: "ujszulott" }
        }
        sort: { fields: childImageSharp___fluid___originalName, order: ASC }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 3200) {
              src
              sizes
              aspectRatio
              presentationHeight
              presentationWidth
              originalName
            }
          }
        }
      }
    }
  `).allFile.nodes.map((item) => ({
    src: item.childImageSharp.fluid.src,
    sizes: item.childImageSharp.fluid.sizes,
    width: item.childImageSharp.fluid.presentationWidth,
    height: item.childImageSharp.fluid.presentationHeight,
  }));

  return (
    <div>
      <SEO
        title="Merkl Kinga - Újszülöttfotózás"
        description="Ez az újszülött fotózás egy különleges stílusa. Különleges feladat megörökíteni az első otthoni pillanatokat, az első öleléseket, puszikat, ringatásokat. Kellékek nélkül készítem a képeket, természetes fény mellett a saját otthonotokba, a meghittséget helyezve előtérbe."
      />
      <Navbar />
      <div className="min-h-256 flex flex-col items-center justify-center bg-0-500">
        <h1 className="font-bold text-4xl text-white">Újszülöttfotózás</h1>
        <Link
          to="/portfolio"
          title="További kategóriák"
          className="text-white mt-8 hover:underline"
        >
          További kategóriák
        </Link>
        <Link
          to="/#csomagokesarak"
          title="Árak"
          className="text-white mt-4 hover:underline"
        >
          Árak
        </Link>
      </div>
      <Masonry images={images} />
      <Footer />
    </div>
  );
};

export default memo(Ujszulott);
